
import {
  Component, InjectReactive, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { ProjectProperties } from 'fsxa-api'
import BaseHeadline from '../../base/BaseHeadline.vue'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import IImage from '../../../shared/general/interfaces/IImage'
import ILocationTeaser from '../../../shared/general/interfaces/ILocationTeaser'
import { globalLabel, globalLabelAsString } from '../../../shared/general/services/StoreService'
import EButtonVariant from '../../../shared/general/enums/EButtonVariant'

@Component({
  name: 'StageLocationPage',
  components: {
    BaseGridLayout,
    BaseHeadline,
    BaseTooltip: () => import('../../base/BaseTooltip.vue'),
    BasePicture: () => import('../../base/BasePicture.vue'),
    TeaserLocationPage: () => import('./TeaserLocationPage.vue'),
    BaseIconButton: () => import('../../base/BaseIconButton.vue'),
  },
})
export default class StageLocationPage extends Vue {
  @InjectReactive({ from: 'globalSettings' }) globalSettings! : ProjectProperties | null

  @Prop({ required: true }) headlines! : string[]

  @Prop({ required: true }) subHeadline! : string

  @Prop({ required: true }) image! : IImage

  @Prop({ default: () => [] }) teasers! : ILocationTeaser[]

  @Prop() numberOfTeasersToDisplay ?: number

  @Prop() locationId ?: string

  @Prop({ default: () => [] }) locationTypes! : string[]

  private isBookmarked : boolean = false

  private hoveringBookmarkButton : boolean = false

  private bookmarkIconStates : Record<string, Record<string, string>> = {
    default: {
      bookmarked: 'bookmark',
      notBookmarked: 'bookmark',
    },
    hover: {
      bookmarked: 'bookmark-slash',
      notBookmarked: 'bookmark',
    },
  }

  private get sliceEnd () : number {
    return this.numberOfTeasersToDisplay ? Math.min(this.numberOfTeasersToDisplay, 3) : 3 // max 3
  }

  private get stageTeasers () : ILocationTeaser[] {
    return this.teasers.slice(0, this.sliceEnd)
  }

  private get isLocationViewSet () : boolean {
    return !!this.$store.state.ToolbarElements.locationSidebarView
  }

  private get bookmarkIcon () : string {
    return this.bookmarkIconStates[this.hoveringBookmarkButton && !this.isMobile
      ? 'hover' : 'default'][this.isBookmarked ? 'bookmarked' : 'notBookmarked']
  }

  private get bookmarkIconType () : string {
    if (!this.isBookmarked) {
      return this.isMobile || !this.hoveringBookmarkButton ? 'light' : 'solid'
    }

    return 'solid'
  }

  private get bookmarkIconVariant () : EButtonVariant {
    return this.isBookmarked ? EButtonVariant.Accent : EButtonVariant.Outline
  }

  private get stageContainerHeight () : string {
    return this.stageTeasers.length > 2 ? 'h-auto' : 'h-auto md:h-[30rem]'
  }

  private get tooltipText () : string {
    return this.isBookmarked ? globalLabelAsString('tooltip_bookmark_post_saved') : globalLabelAsString('tooltip_bookmark_pre_saved')
  }

  private get isMobile () : boolean {
    return this.$store.getters['Viewport/isMobile']
  }

  private async saveLocation () : Promise<void> {
    if (!this.locationTypes.length) return

    const { isBookmarked } = this
    const actionType = isBookmarked ? 'Locations/deleteLocation' : 'Locations/saveLocation'
    const payload = (locationType : string) => (isBookmarked ? locationType : { locationId: this.locationId, locationType })

    const locationActions = await Promise.all(
      this.locationTypes.map((locationType) => this.$store.dispatch(actionType, payload(locationType))),
    )

    if (locationActions.every((action) => action)) {
      this.$store.commit('Snackbar/set', globalLabel(isBookmarked ? 'snackbar_location_removed_label' : 'snackbar_location_saved_label'))
    }
  }

  @Watch('locationId')
  @Watch('$store.state.Locations.savedLocations', { deep: true, immediate: true })
  async savedLocationsChange () : Promise<void> {
    if (!this.locationId) return
    const isLocationIdSaved : boolean = await this.$store.dispatch('Locations/isLocationIdSaved', this.locationId)
    this.isBookmarked = isLocationIdSaved

    if (isLocationIdSaved) {
      this.$store.commit('MessageBar/set', {
        color: 'green',
        text: globalLabelAsString('location_saved_location_page'),
        iconName: 'bookmark',
        iconType: 'solid',
      })
    } else {
      this.$store.commit('MessageBar/reset')
    }
  }
}
